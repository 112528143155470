/**
 * Copyright 2024 Illumio, Inc. All Rights Reserved.
 */
import intl from '@illumio-shared/utils/intl';
import keyMirror from 'keymirror';

export const attributeIds = keyMirror({
  os: null,
  hostname: null,
  ip: null,
  port: null,
  process: null,
});

export const operatorIds = keyMirror({
  is: null,
  startsWith: null,
  contains: null,
  endsWith: null,
  isIn: null,
  regex: null,
});

export const logicOperatorIds = keyMirror({
  and: null,
  not: null,
  or: null,
});

export const valueIds = {
  [attributeIds.os]: keyMirror({
    windows: null,
    linux: null,
    darwin: null,
    aix: null,
    solaris: null,
  }),
};

export const attributeLabels = {
  [attributeIds.os]: intl('Common.OS'),
  [attributeIds.hostname]: intl('Common.Hostname'),
  [attributeIds.ip]: intl('Common.IPAddress'),
  [attributeIds.port]: intl('Port.Protocol'),
  [attributeIds.process]: intl('Common.Process'),
};

export const operatorLabels = {
  [operatorIds.is]: intl('LabelRules.OperatorIs'),
  [operatorIds.startsWith]: intl('LabelRules.OperatorStartsWith'),
  [operatorIds.contains]: intl('LabelRules.OperatorContains'),
  [operatorIds.endsWith]: intl('LabelRules.OperatorEndsWith'),
  [operatorIds.isIn]: intl('LabelRules.OperatorIsIn'),
  [operatorIds.regex]: intl('LabelRules.OperatorRegex'),
};

export const valueLabels = {
  [attributeIds.os]: {
    [valueIds.os.windows]: intl('Workloads.Unpair.Options.OperatingSystems.Windows'),
    [valueIds.os.linux]: intl('Workloads.Unpair.Options.OperatingSystems.Linux'),
    [valueIds.os.darwin]: intl('Workloads.Unpair.Options.OperatingSystems.MacOS'),
    [valueIds.os.aix]: intl('Workloads.Unpair.Options.OperatingSystems.AIX'),
    [valueIds.os.solaris]: intl('Workloads.Unpair.Options.OperatingSystems.Solaris'),
  },
};

export const logicOperatorLabels = {
  [logicOperatorIds.and]: intl('LabelRules.LogicOperatorAnd'),
  [logicOperatorIds.not]: intl('LabelRules.LogicOperatorNot'),
  [logicOperatorIds.or]: intl('LabelRules.LogicOperatorOr'),
};
