/**
 * Copyright 2022 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import intl from '@illumio-shared/utils/intl';
import {createSelector} from 'reselect';
import {Diff, Icon, IPListEditor, Link, OptionSelector, Pill, StatusIcon} from 'components';
import {Selector} from 'containers';
import EndpointSelector from '../EndpointSelector';
import {getRouteParams, getIsCSFrame} from 'containers/App/AppState';
import {getDisplayNames, getTypeInitialRegExp} from 'containers/Label/LabelSettings/LabelSettingState';
import {getConsumerProviderArrowDirection} from 'containers/App/AppUtils';
import {
  getInitialEndpoint,
  getInitialRuleOptions,
  getRuleOptionsPills,
  formatNote,
  formatStatus,
  formatButtons,
  stringifyStatement,
  newRuleHref,
  saveServiceRef,
  getInitialServices,
  formatProposedDiffStatus,
} from '../RulesetItemUtils';
import {
  consumerServicesCategories,
  getReceiverCategories,
  providerServiceCategories,
  ruleOptionsCategories,
} from './RulesetRulesSelectorConfig';
import stylesUtils from 'utils.css';
import styles from '../RulesetItem.css';
import {getServicePills} from 'containers/EnforcementBoundaries/EnforcementBoundariesUtils';
import {isUserScoped} from 'containers/User/UserState';
import {hrefUtils} from '@illumio-shared/utils';
import * as GridUtils from 'components/Grid/GridUtils';

const intrascopeExtrascopeOptionsObj = [
  {value: false, label: intl('AppGroupCoverage.IntraScope')},
  {value: true, label: intl('AppGroupCoverage.ExtraScope')},
];

const ipVersionOptionsObj = createSelector([], () => [
  {value: '4', label: intl('Protocol.IPv4')},
  {value: '6', label: intl('Protocol.IPv6')},
]);

export const getRuleServices = (type, {labelTypesNameObj, isCSFrame, isScopedUser}) => {
  return {
    header: type === 'providers' ? intl('Rulesets.Rules.DestinationServices') : intl('Explorer.SourceProcessService'),
    disabled: !(__ANTMAN__ || __TARGET__ === 'core') && type === 'consumers',
    onMouseOver: ({evt, elements}) => {
      return Object.values(elements).every(element => !element?.contains(evt.target));
    },
    refs: ({row: {isInEditMode, data: {rule, oldRule} = {}}}) => {
      if (isInEditMode) {
        return;
      }

      const serviceRule = type === 'providers' ? rule?.ingress_services : rule?.egress_services;
      const oldServiceRule = type === 'providers' ? oldRule?.ingress_services : oldRule?.egress_services;

      const value = saveServiceRef({services: serviceRule});
      const oldValue = oldRule ? saveServiceRef({services: oldServiceRule, version: 'active'}) : undefined;

      return {...value, ...oldValue};
    },
    format: ({
      row: {isInEditMode, data: {resourcesInError, errors, rule, oldRule, pversion} = {}},
      component: {onServiceChange, onBlur} = {},
      refs,
    }) => {
      const serviceRule = type === 'providers' ? rule?.ingress_services : rule?.egress_services;
      const oldServiceRule = type === 'providers' ? oldRule?.ingress_services : oldRule?.egress_services;
      const serviceErrors = type === 'providers' ? errors?.services : errors?.egressServices;

      if (isInEditMode) {
        return (
          <Selector
            tid={type === 'providers' ? 'service-selector' : 'sourceprocess-selector'}
            hideClearAll
            maxColumns={3}
            values={getInitialServices(serviceRule)}
            placeholder={intl('Common.Steps.SelectServices')}
            noActiveIndicator
            categories={
              type === 'providers'
                ? providerServiceCategories({labelTypesNameObj, isCSFrame, isScopedUser})
                : consumerServicesCategories({labelTypesNameObj, isScopedUser})
            }
            onSelectionChange={_.partial(onServiceChange, type)}
            inputProps={{onBlur}}
            errorMessage={serviceErrors?.length > 0 ? '' : undefined}
            errors={type === 'providers' ? resourcesInError?.services : resourcesInError?.egressServices}
            footerProps={{filteringTipsContent: intl('ObjectSelector.FilteringTipsContent')}}
          />
        );
      }

      const labelElementsValue = getServicePills({
        services: serviceRule,
        refs,
        version: pversion,
      });

      const labelElementsOldValue = oldRule
        ? getServicePills({
            services: oldServiceRule,
            refs,
            version: pversion,
          })
        : null;

      let policyElements;

      if (labelElementsValue) {
        policyElements = Object.keys(labelElementsValue).reduce((elements, newCur) => {
          if (labelElementsValue[newCur]?.length || labelElementsOldValue?.[newCur]?.length) {
            elements.push(
              <Pill.Diff
                key={newCur}
                value={labelElementsValue[newCur]}
                oldValue={labelElementsOldValue?.[newCur]}
                noDiff={!oldRule}
                notExpandable
              />,
            );
          }

          return elements;
        }, []);
      }

      return policyElements ? (
        <div className={`${stylesUtils.gapXSmall} ${stylesUtils.gapHorizontalWrap}`}>{policyElements}</div>
      ) : null;
    },
    sortable: false,
  };
};

export const getRuleEntitiesColumn = (entityType, tab) => ({
  header:
    entityType === 'providers'
      ? intl('Common.Destinations')
      : intl(!__ANTMAN__ && tab === 'extrascope' ? 'Common.ConsumersGlobal' : 'Common.Sources'),
  refs: {[entityType]: instance => instance?.element},
  onMouseOver: ({evt, elements}) => {
    if (elements[entityType]) {
      for (const element of elements[entityType].values()) {
        if (element.contains(evt.target)) {
          return false;
        }
      }
    }

    return true;
  },
  format: ({
    row: {isInEditMode, type, data: {resourcesInError, resourcesInWarning, errors, rule, oldRule} = {}},
    component: {onEndpointChange, onBlur} = {},
    refs,
  }) =>
    isInEditMode ? (
      <EndpointSelector
        type={entityType}
        showUserGroups={entityType === 'consumers'}
        showContainerHost={entityType === 'consumers'}
        showVirtualServers={entityType === 'providers'}
        values={getInitialEndpoint(rule, entityType)}
        onSelectionChange={_.partial(onEndpointChange, entityType)}
        errorMessage={errors?.[entityType]?.length > 0 ? '' : undefined}
        errors={resourcesInError?.[entityType]}
        warnings={resourcesInWarning?.[entityType]}
        inputProps={{onBlur}}
        isDenyRule={type === 'deny' || type === 'overrideDeny'}
        isGlobalConsumer={rule?.type === 'extrascope' && entityType === 'consumers'}
      />
    ) : (
      <Pill.Endpoint
        ref={refs[entityType]}
        type={entityType}
        showUserGroups={entityType === 'consumers'}
        value={rule}
        oldValue={oldRule}
        notExpandable
      />
    ),
  sortable: false,
});

const coreTemplates = [
  {
    getData() {
      return {
        arrow: getConsumerProviderArrowDirection(this.isReverseProviderConsumer, 'horizontal'),
      };
    },
    template() {
      const isReverseProviderConsumer = this.isReverseProviderConsumer;

      const consumers = [
        {columns: ['extrascope'], size: 'max-content'},
        {columns: ['consumers'], size: 'minmax(150px, 1fr)'},
        {columns: ['consumingServices'], size: 'minmax(150px, 1fr)'},
      ];

      const providers = [
        {columns: ['providers'], size: 'minmax(150px, 1fr)'},
        {columns: ['providingServices'], size: 'minmax(150px, 1fr)'},
      ];

      return [
        {columns: ['dragbar'], size: 'var(--25px)'},
        {columns: ['checkboxes'], size: 'max-content'},
        {columns: ['proposedDiffStatus'], size: 'max-content'},
        {columns: ['rowStatus'], size: 'max-content'},
        {columns: ['diffStatus'], size: 'max-content'},
        {columns: ['ruleNumber'], size: 'max-content'},
        {columns: ['messages'], span: [-6, 11], extraClass: styles.separator},
        {columns: ['state'], size: 'minmax(120px, max-content)'},
        ...(isReverseProviderConsumer ? consumers : providers),
        {columns: ['arrow'], size: 'max-content'},
        ...(isReverseProviderConsumer ? providers : consumers),
        {columns: ['ruleOptions'], size: 'minmax(50px, 1fr)'},
        {columns: ['note'], size: 'minmax(60px, max-content)'},
        {columns: ['ruleType'], size: 'var(--80px)'},
        {columns: ['buttons'], size: 'min-content'},
      ];
    },
  },
  {
    maxWidth: 1440,
    getData() {
      return {
        arrow: getConsumerProviderArrowDirection(this.isReverseProviderConsumer, 'horizontal'),
      };
    },
    template() {
      const isReverseProviderConsumer = this.isReverseProviderConsumer;
      const consumers = [{columns: ['extrascope', 'consumers', 'consumingServices'], size: 'minmax(150px, 1fr)'}];
      const providers = [{columns: ['providers', 'providingServices'], size: 'minmax(150px, 1fr)'}];

      return [
        {columns: ['dragbar'], size: 'var(--25px)'},
        {columns: ['checkboxes'], size: 'max-content'},
        {columns: ['proposedDiffStatus'], size: 'max-content'},
        {columns: ['rowStatus'], size: 'max-content'},
        {columns: ['diffStatus'], size: 'max-content'},
        {columns: ['ruleNumber'], size: 'max-content'},
        {columns: ['messages'], span: [-6, 8], extraClass: styles.separator},
        {columns: ['state'], size: 'minmax(120px, max-content)'},
        ...(isReverseProviderConsumer ? consumers : providers),
        {columns: ['arrow'], size: 'max-content'},
        ...(isReverseProviderConsumer ? providers : consumers),
        {columns: ['ruleOptions'], size: 'minmax(50px, 200px)'},
        {columns: ['note'], size: 'minmax(35px, max-content)'},
        {columns: ['ruleType'], size: 'var(--80px)'},
        {columns: ['buttons'], size: 'min-content'},
      ];
    },
  },
  {
    maxWidth: 1366,
    getData() {
      return {
        arrow: getConsumerProviderArrowDirection(this.isReverseProviderConsumer, 'horizontal'),
      };
    },
    template() {
      const isReverseProviderConsumer = this.isReverseProviderConsumer;
      const consumers = ['extrascope', 'consumers', 'consumingServices'];
      const providers = ['providers', 'providingServices'];

      return [
        {columns: ['dragbar'], size: 'var(--25px)'},
        {columns: ['checkboxes'], size: 'max-content'},
        {columns: ['proposedDiffStatus'], size: 'max-content'},
        {columns: ['rowStatus'], size: 'max-content'},
        {columns: ['diffStatus'], size: 'max-content'},
        {columns: ['ruleNumber'], size: 'max-content'},
        {columns: ['messages'], span: [-6, 6], extraClass: styles.separator},
        {columns: ['state'], size: 'minmax(120px, max-content)'},
        {
          columns: [...(isReverseProviderConsumer ? consumers : providers)],
          size: 'minmax(170px, 1fr)',
        },
        {
          columns: [...(isReverseProviderConsumer ? providers : consumers), 'ruleOptions'],
          size: 'minmax(170px, 1fr)',
        },
        {columns: ['note'], size: 'minmax(35px, max-content)'},
        {columns: ['ruleType'], size: 'var(--80px)'},
        {columns: ['buttons'], size: 'min-content'},
      ];
    },
  },
  {
    maxWidth: 1152,
    getData() {
      return {
        arrow: getConsumerProviderArrowDirection(this.isReverseProviderConsumer, 'horizontal'),
      };
    },
    template() {
      const isReverseProviderConsumer = this.isReverseProviderConsumer;
      const consumers = ['extrascope', 'consumers', 'consumingServices'];
      const providers = ['providers', 'providingServices'];

      return [
        {columns: ['dragbar'], size: 'var(--25px)'},
        {columns: ['checkboxes'], size: 'max-content'},
        {columns: ['proposedDiffStatus'], size: 'max-content'},
        {columns: ['rowStatus'], size: 'max-content'},
        {columns: ['diffStatus'], size: 'max-content'},
        {columns: ['ruleNumber'], size: 'max-content'},
        {columns: ['messages'], span: [-6, 6], extraClass: styles.separator},
        {columns: ['state', ...(isReverseProviderConsumer ? consumers : providers)], size: 'minmax(200px, 1fr)'},
        {columns: ['arrow'], size: 'max-content'},
        {
          columns: [...(isReverseProviderConsumer ? providers : consumers), 'ruleOptions'],
          size: 'minmax(270px, 1fr)',
        },
        {columns: ['note'], size: 'minmax(35px, max-content)'},
        {columns: ['ruleType'], size: 'var(--80px)'},
        {columns: ['buttons'], size: 'min-content'},
      ];
    },
  },
  {
    maxWidth: 780,
    getData() {
      return {
        arrow: getConsumerProviderArrowDirection(this.isReverseProviderConsumer, 'vertical'),
      };
    },
    template() {
      const isReverseProviderConsumer = this.isReverseProviderConsumer;
      const consumers = ['extrascope', 'consumers', 'consumingServices'];
      const providers = ['providers', 'providingServices'];

      return [
        {columns: ['dragbar'], size: 'var(--25px)'},
        {columns: ['checkboxes'], size: 'max-content'},
        {columns: ['proposedDiffStatus', 'diffStatus', 'rowStatus'], size: 'max-content'},
        {columns: ['ruleNumber'], size: 'max-content'},
        {columns: ['messages'], span: [-4, 4], extraClass: styles.separator},
        {
          columns: [
            'state',
            ...(isReverseProviderConsumer ? consumers : providers),
            'arrow',
            ...(isReverseProviderConsumer ? providers : consumers),
            'ruleOptions',
          ],
          size: 'minmax(120px, 1fr)',
        },
        {columns: ['note'], size: 'minmax(35px, max-content)'},
        {columns: ['ruleType'], size: 'var(--80px)'},
        {columns: ['buttons'], size: 'min-content'},
      ];
    },
  },
];

const coreXTemplates = [
  {
    getData() {
      return {
        arrow: getConsumerProviderArrowDirection(this.isReverseProviderConsumer, 'horizontal'),
      };
    },
    template() {
      const isReverseProviderConsumer = this.isReverseProviderConsumer;

      const consumers = [
        {columns: ['consumers'], size: '1fr'},
        {columns: ['consumingServices'], size: '1fr'},
      ];

      const providers = [
        {columns: ['providers'], size: '1fr'},
        {columns: ['providingServices'], size: '1fr'},
      ];

      return [
        {columns: ['dragbar'], size: 'var(--25px)'},
        {columns: ['checkboxes'], size: 'max-content'},
        {columns: ['rowStatus'], size: 'max-content'},
        {columns: ['diffStatus'], size: 'var(--100px)'},
        {columns: ['ruleNumber'], size: 'max-content'},
        {columns: ['state'], size: 'minmax(120px, max-content)'},
        ...(isReverseProviderConsumer ? consumers : providers),
        {columns: ['arrow'], size: 'max-content'},
        ...(isReverseProviderConsumer ? providers : consumers),
        {columns: ['ruleOptions'], size: 'minmax(150px, 1fr)'},
        {columns: ['note'], size: 'var(--60px)'},
        {columns: ['ruleType'], size: 'max-content'},
        {columns: ['buttons'], size: 'var(--95px)'},
      ];
    },
  },
  {
    maxWidth: 1440,
    getData() {
      return {
        arrow: getConsumerProviderArrowDirection(this.isReverseProviderConsumer, 'horizontal'),
      };
    },
    template() {
      const isReverseProviderConsumer = this.isReverseProviderConsumer;
      const consumers = [{columns: ['consumers', 'consumingServices'], size: '1fr'}];
      const providers = [{columns: ['providers', 'providingServices', 'ruleOptions'], size: '1fr'}];

      return [
        {columns: ['dragbar'], size: 'var(--25px)'},
        {columns: ['checkboxes'], size: 'max-content'},
        {columns: ['rowStatus'], size: 'max-content'},
        {columns: ['diffStatus'], size: 'var(--100px)'},
        {columns: ['ruleNumber'], size: 'max-content'},
        {columns: ['state'], size: 'minmax(120px, max-content)'},
        ...(isReverseProviderConsumer ? consumers : providers),
        {columns: ['arrow'], size: 'max-content'},
        ...(isReverseProviderConsumer ? providers : consumers),
        {columns: ['note'], size: 'var(--42px)'},
        {columns: ['ruleType'], size: 'max-content'},
        {columns: ['buttons'], size: 'var(--95px)'},
      ];
    },
  },
  {
    maxWidth: 960,
    getData() {
      return {
        arrow: getConsumerProviderArrowDirection(this.isReverseProviderConsumer, 'horizontal'),
      };
    },
    template() {
      const isReverseProviderConsumer = this.isReverseProviderConsumer;
      const consumers = ['state', 'consumers', 'consumingServices'];
      const providers = ['providers', 'providingServices', 'ruleOptions'];

      return [
        {columns: ['dragbar'], size: 'var(--25px)'},
        {columns: ['checkboxes'], size: 'max-content'},
        {columns: ['rowStatus'], size: 'max-content'},
        {columns: ['ruleType', 'diffStatus'], size: 'var(--100px)'},
        {columns: ['ruleNumber'], size: 'max-content'},
        {columns: isReverseProviderConsumer ? consumers : providers, size: '1fr'},
        {columns: ['arrow'], size: 'max-content'},
        {columns: isReverseProviderConsumer ? providers : consumers, size: '1fr'},
        {columns: ['note'], size: 'var(--42px)'},
        {columns: ['buttons'], size: 'var(--95px)'},
      ];
    },
  },
  {
    maxWidth: 740,
    getData() {
      return {
        arrow: getConsumerProviderArrowDirection(this.isReverseProviderConsumer, 'vertical'),
      };
    },
    template() {
      const isReverseProviderConsumer = this.isReverseProviderConsumer;
      const consumers = ['consumers', 'consumingServices'];
      const providers = ['providers', 'providingServices'];

      return [
        {columns: ['dragbar'], size: 'var(--25px)'},
        {columns: ['checkboxes'], size: 'max-content'},
        {columns: ['rowStatus'], size: 'max-content'},
        {columns: ['ruleType', 'diffStatus'], size: 'var(--100px)'},
        {columns: ['ruleNumber'], size: 'max-content'},
        {
          columns: [
            'state',
            ...(isReverseProviderConsumer ? consumers : providers),
            'arrow',
            ...(isReverseProviderConsumer ? providers : consumers),
            'ruleOptions',
          ],
          size: '1fr',
        },
        {columns: ['note'], size: 'var(--42px)'},
        {columns: ['buttons'], size: 'var(--95px)'},
      ];
    },
  },
];

export const getViewRuleTemplates = (isReverseProviderConsumer, isAllow) => [
  {
    getData() {
      return {
        arrow: getConsumerProviderArrowDirection(isReverseProviderConsumer, 'horizontal'),
      };
    },
    template() {
      const consumers = [
        {columns: isAllow ? ['extrascope'] : [], size: 'minmax(100px, 1fr)'},
        {columns: ['consumers'], size: 'minmax(150px, 1fr)'},
        {columns: ['consumingServices'], size: 'minmax(150px, 1fr)'},
      ];

      const providers = [
        {columns: ['providers'], size: 'minmax(150px, 1fr)'},
        {columns: ['providingServices'], size: 'minmax(150px, 1fr)'},
      ];

      return [
        {columns: ['ruleset'], size: 'minmax(100px, 1fr)'},
        {columns: ['scope'], size: 'minmax(100px, 1fr)'},
        ...(isReverseProviderConsumer ? consumers : providers),
        {columns: ['arrow'], size: 'max-content'},
        ...(isReverseProviderConsumer ? providers : consumers),
        {columns: ['ruleOptions'], size: 'minmax(80px, 100px)'},
        {columns: ['ruleType'], size: 'var(--80px)'},
      ];
    },
  },
  {
    maxWidth: 1440,
    getData() {
      return {
        arrow: getConsumerProviderArrowDirection(isReverseProviderConsumer, 'horizontal'),
      };
    },
    template() {
      const consumers = [
        {columns: isAllow ? ['extrascope'] : [], size: 'minmax(100px, 1fr)'},
        {columns: ['consumers', 'consumingServices'], size: 'minmax(150px, 1fr)'},
      ];
      const providers = [{columns: ['providers', 'providingServices'], size: 'minmax(150px, 1fr)'}];

      return [
        {columns: ['ruleset'], size: 'minmax(150px, max-content)'},
        {columns: ['scope'], size: 'minmax(150px, 1fr)'},
        ...(isReverseProviderConsumer ? consumers : providers),
        {columns: ['arrow'], size: 'max-content'},
        ...(isReverseProviderConsumer ? providers : consumers),
        {columns: ['ruleOptions'], size: 'minmax(100px, max-content)'},
        {columns: ['ruleType'], size: 'var(--80px)'},
      ];
    },
  },
  {
    maxWidth: 1152,
    getData() {
      return {
        arrow: getConsumerProviderArrowDirection(isReverseProviderConsumer, 'horizontal'),
      };
    },
    template() {
      const consumersSize = [{columns: ['consumers', 'consumingServices'], size: 'minmax(150px, 1fr)'}];
      const providersSize = [{columns: ['providers', 'providingServices'], size: 'minmax(150px, 1fr)'}];

      const consumers = ['consumers', 'consumingServices'];
      const providers = ['providers', 'providingServices'];

      return [
        {columns: ['ruleset'], size: 'minmax(100px, max-content)'},
        {columns: ['scope'], size: 'minmax(100px, 1fr)'},
        ...(isAllow && isReverseProviderConsumer ? [{columns: ['extrascope'], size: 'minmax(100px, 1fr)'}] : []),
        ...(isReverseProviderConsumer ? consumersSize : providersSize),
        {columns: ['arrow'], size: 'max-content'},
        ...(!isAllow || isReverseProviderConsumer ? [] : [{columns: ['extrascope'], size: 'minmax(100px, 1fr)'}]),
        {
          columns: [...(isReverseProviderConsumer ? providers : consumers), 'ruleOptions'],
          size: 'minmax(150px, 1fr)',
        },
        {columns: ['ruleType'], size: 'var(--80px)'},
      ];
    },
  },
  {
    maxWidth: 760,
    getData() {
      return {
        arrow: getConsumerProviderArrowDirection(isReverseProviderConsumer, 'horizontal'),
      };
    },
    template() {
      const consumers = ['consumers', 'consumingServices'];
      const providers = ['providers', 'providingServices'];

      return [
        {columns: ['ruleset'], size: 'minmax(100px, max-content)'},
        {columns: ['scope'], size: 'minmax(100px, 1fr)'},
        ...(isAllow && isReverseProviderConsumer ? [{columns: ['extrascope'], size: 'minmax(100px, 1fr)'}] : []),
        {columns: [...(isReverseProviderConsumer ? consumers : providers)], size: 'minmax(200px, 1fr)'},
        {columns: ['arrow'], size: 'max-content'},
        ...(!isAllow || isReverseProviderConsumer ? [] : [{columns: ['extrascope'], size: 'minmax(100px, 1fr)'}]),
        {
          columns: [...(isReverseProviderConsumer ? providers : consumers), 'ruleOptions'],
          size: 'minmax(270px, 1fr)',
        },
        {columns: ['ruleType'], size: 'var(--80px)'},
      ];
    },
  },
  {
    maxWidth: 540,
    getData() {
      return {
        arrow: getConsumerProviderArrowDirection(isReverseProviderConsumer, 'vertical'),
      };
    },
    template() {
      const consumers = ['consumers', 'consumingServices'];
      const providers = ['providers', 'providingServices'];

      return [
        {columns: ['ruleset', 'scope'], size: 'minmax(150px, max-content)'},
        // if the source is on top we can show the global by the side by putting it in a new column
        ...(isAllow && isReverseProviderConsumer ? [{columns: ['extrascope'], size: 'minmax(60px, 1fr)'}] : []),
        {
          columns: [
            ...(isReverseProviderConsumer ? consumers : providers),
            'arrow',
            ...(isReverseProviderConsumer
              ? providers
              : [...(!isAllow || isReverseProviderConsumer ? [] : ['extrascope']), ...consumers]),
            'ruleOptions',
          ],
          size: 'minmax(120px, 1fr)',
        },
        {columns: ['ruleType'], size: 'max-content'},
      ];
    },
  },
];

export const rulesGridSettings = createSelector(
  [getRouteParams, getDisplayNames, getIsCSFrame, isUserScoped],
  ({tab, pversion}, labelTypesNameObj, isCSFrame, isScopedUser) => ({
    id: 'rules',
    showManager: false,
    columns: {
      dragbar: {
        sortable: false,
      },
      proposedDiffStatus: {
        sortable: false,
        disabled: true,
        format: formatProposedDiffStatus,
      },
      checkboxes: {
        disabled: pversion !== 'draft',
      },
      rowStatus: {
        sortable: false,
        statusIconProps: {interactive: true},
      },
      ruleNumber: {
        header: intl('Rulesets.Rules.IndexColumnLabel'),
        sortable: false,
        format: ({
          row: {
            data: {ruleNumber},
            isInEditMode,
          },
        }) => (isInEditMode ? null : String(ruleNumber)),
      },
      diffStatus: {...GridUtils.getProvisionStatusColumn(), sortable: false},
      state: {
        header: intl('Common.Status'),
        format: formatStatus,
        sortable: false,
      },
      providingServices: getRuleServices('providers', {labelTypesNameObj, isCSFrame, isScopedUser}),
      consumingServices: {
        ...getRuleServices('consumers', {isScopedUser}),
        ...(isCSFrame && {disabled: true}),
      },
      arrow: {},
      consumers: getRuleEntitiesColumn('consumers', tab),
      providers: getRuleEntitiesColumn('providers', tab),
      ruleOptions: {
        header: intl('Rulesets.Rules.RuleOptions'),
        format: ({
          row: {isInEditMode, data: {resourcesInError, errors, rule, oldRule, type} = {}},
          component: {onRuleOptionsChange, onBlur} = {},
        }) => {
          if (isInEditMode) {
            return (
              <Selector
                tid="rule-options-selector"
                hideClearAll
                noActiveIndicator
                placeholder={intl('Rulesets.Rules.SelectRuleOptions')}
                dropdownMaxHeight={200}
                values={getInitialRuleOptions(rule, type)}
                categories={ruleOptionsCategories(rule)}
                onSelectionChange={onRuleOptionsChange}
                footerProps={{noFilteringTips: true}}
                errorMessage={errors?.ruleOptions?.length > 0 ? '' : undefined}
                errors={resourcesInError?.ruleOptions}
                inputProps={{onBlur}}
              />
            );
          }

          const pillPropsDiff = {value: getRuleOptionsPills(rule)};

          if (oldRule) {
            pillPropsDiff.oldValue = getRuleOptionsPills(oldRule);
          } else {
            pillPropsDiff.noDiff = true;
          }

          return <Pill.Diff {...pillPropsDiff} notExpandable />;
        },
        sortable: false,
        ...(isCSFrame && {disabled: true}),
      },
      note: {
        value: 'description',
        sortable: false,
        refs: {
          noteIcon: icon => icon?.element,
        },
        onMouseOver: ({evt, elements}) => !elements.noteIcon?.contains(evt.target),
        format: formatNote,
      },
      ruleType: {
        disabled: !__ANTMAN__ && !['extrascope', 'intrascope'].includes(tab),
        sortable: false,
        format: ({row: {type}}) => {
          if (type === 'separator') {
            return null;
          }

          if (type === 'allow') {
            return <StatusIcon status="check" label={intl('Common.Allow')} theme={styles} themePrefix="ruletype-" />;
          }

          return <StatusIcon status="deny" label={intl('Common.Deny')} theme={styles} themePrefix="ruletype-" />;
        },
      },
      ruleset: {
        header: intl('Common.Policies'),
        manager: false,
        sortable: false,
        format: ({row: {data}}) => (
          <Link
            to="rulesets.item"
            params={{
              id: hrefUtils.getId(data.ruleset.href),
              pversion: 'draft',
              tab: data.unscoped_consumers ? 'extrascope' : 'intrascope',
            }}
          >
            {data.ruleset.name}
          </Link>
        ),
      },
      extrascope: {
        header: intl('Rulesets.Rules.ScopeType'),
        disabled: !__ANTMAN__ && tab === undefined,
        manager: false,
        sortable: false,
        format: ({
          row: {
            isInEditMode,
            data: {scopes, unscoped_consumers},
            type,
          },
          component: {onExtrascopeChange, onBlur},
        }) => {
          if (scopes?.[0]?.length > 0 && type === 'allow') {
            if (isInEditMode) {
              return (
                <OptionSelector
                  disableErrorMessage
                  theme={styles}
                  tid="unscoped_consumers"
                  name="unscoped_consumers"
                  initialValue={intrascopeExtrascopeOptionsObj.find(({value}) => value === unscoped_consumers)}
                  onAfterChange={onExtrascopeChange}
                  options={intrascopeExtrascopeOptionsObj}
                  onBlur={onBlur}
                />
              );
            }

            if (unscoped_consumers) {
              return (
                <div className={styles.ScopeSource}>
                  <Icon name="global-rule" theme={styles} themePrefix="extrascope-" />
                  <span>{intl('AppGroupCoverage.ExtraScope')}</span>
                </div>
              );
            }

            return (
              <div className={styles.ScopeSource}>
                <span className={styles.emptyIconSpace} />
                <span>{intl('AppGroupCoverage.IntraScope')}</span>
              </div>
            );
          }
        },
      },
      scope: {
        header: intl('Common.Scope'),
        manager: false,
        sortable: false,
        format: ({
          row: {
            data: {scopes},
          },
        }) =>
          scopes.map(scope => {
            return scope.map(({label, label_group, exclusion}) => (
              <Pill.Label
                key={label?.href ?? label_group?.href}
                type={label?.key ?? label_group?.key}
                href={label?.href ?? label_group?.href}
                group={Boolean(label_group)}
                pversion="draft"
                exclusion={exclusion}
              >
                {label?.value ?? label_group?.name}
              </Pill.Label>
            ));
          }),
      },
      buttons: {
        sortable: false,
        format: formatButtons,
      },
      messages: {
        value: () => '',
      },
    },
    // in coreX we have 3 grids on ruleset page & we want to keep a consistent size across all columns && all grids
    templates: __ANTMAN__ ? coreXTemplates : coreTemplates,
  }),
);

export const ipTablesGridSettings = createSelector(
  [getRouteParams, getDisplayNames, getTypeInitialRegExp],
  ({pversion}, labelTypesNameObj, queryKeywordsRegex) => ({
    id: 'iptablesrules', // Use to reference parameter route query name e.g. ':?scope?:boundarieseruleslist'
    showManager: false,
    columns: {
      checkboxes: {
        disabled: pversion !== 'draft',
      },
      rowStatus: {
        sortable: false,
        statusIconProps: {interactive: true},
      },
      diffStatus: {...GridUtils.getProvisionStatusColumn(), sortable: false},
      state: {
        header: intl('Common.Status'),
        format: formatStatus,
        sortable: false,
      },
      receivers: {
        header: intl('Rulesets.Rules.IpTables.Actors'),
        sortable: false,
        refs: {receivers: instance => instance?.element},
        onMouseOver: ({evt, elements}) => {
          if (elements.receivers) {
            for (const element of elements.receivers.values()) {
              if (element.contains(evt.target)) {
                return false;
              }
            }
          }

          return true;
        },
        format: ({
          row: {isInEditMode, data: {resourcesInError, errors, resourcesInWarning, rule, oldRule} = {}},
          component: {onReceiversChange, onBlur} = {},
          refs,
        }) =>
          isInEditMode ? (
            <Selector
              tid="receivers-selector"
              noActiveIndicator
              categories={getReceiverCategories({
                warnings: resourcesInWarning?.receivers,
                labelTypesNameObj,
                queryKeywordsRegex,
              })}
              values={getInitialEndpoint(rule, 'actors')}
              onSelectionChange={onReceiversChange}
              errorMessage={errors?.receivers?.length > 0 ? '' : undefined}
              errors={resourcesInError?.receivers}
              inputProps={{onBlur}}
            />
          ) : (
            <Pill.Endpoint ref={refs.receivers} type="actors" value={rule} oldValue={oldRule} notExpandable />
          ),
      },
      ipVersion: {
        header: intl('Rulesets.Rules.IpTables.Form.IpVersion'),
        sortable: false,
        value: 'ip_version',
        format: ({
          row: {
            isInEditMode,
            data: {rule, oldRule},
          },
          component: {onIpVersionChange, onBlur},
        }) => {
          if (isInEditMode) {
            return (
              <OptionSelector
                disableErrorMessage
                theme={styles}
                tid="ip_version"
                name="ip_version"
                initialValue={ipVersionOptionsObj().find(({value}) => value === (rule.ip_version ?? '4'))}
                onAfterChange={onIpVersionChange}
                options={ipVersionOptionsObj()}
                onBlur={onBlur}
              />
            );
          }

          return (
            <Diff.Option
              noDiff={!oldRule}
              value={rule.ip_version === '4' ? intl('Protocol.IPv4') : intl('Protocol.IPv6')}
              oldValue={oldRule?.ip_version === '4' ? intl('Protocol.IPv4') : intl('Protocol.IPv6')}
            />
          );
        },
      },
      statements: {
        header: intl('Rulesets.Rules.IpTables.Grid.Statements'),
        sortable: false,
        format: ({
          row: {
            isInEditMode,
            data: {rule, oldRule, errors},
          },
          component: {onIpRulesStatementsChange, onBlur},
        }) => {
          if (isInEditMode) {
            //ToDo : As a workaround we are using IPListEditor with limited validations, refactor this with new component for statements
            return (
              <div className={styles.statementsEditor}>
                <IPListEditor
                  tid="iptable-rule-statements"
                  placeholder={intl('Rulesets.Rules.IpTables.Form.StatementsPlaceholder')}
                  initialValue={
                    rule.statements?.map(statement => ({
                      fqdn: stringifyStatement(statement),
                      original: {fqdn: stringifyStatement(statement)},
                    })) ?? []
                  }
                  showModified={rule.href !== newRuleHref}
                  onChange={onIpRulesStatementsChange}
                  disableValidation
                  disableErrorMessage={false}
                  errorMessage={errors?.statements?.length > 0 ? '' : false}
                  handleBlur={onBlur}
                />
              </div>
            );
          }

          return (
            <Diff.List
              value={rule.statements.map(stringifyStatement)}
              oldValue={oldRule?.statements?.map(stringifyStatement)}
              noDiff={!oldRule}
              tid="statements"
            />
          );
        },
      },
      note: {
        value: 'description',
        sortable: false,
        refs: {
          noteIcon: icon => icon?.element,
        },
        onMouseOver: ({evt, elements}) => !elements.noteIcon?.contains(evt.target),
        format: formatNote,
      },
      buttons: {
        sortable: false,
        format: formatButtons,
      },
    },
    templates: [
      [
        {columns: ['checkboxes'], size: 'max-content'},
        {columns: ['rowStatus'], size: 'max-content'},
        {columns: ['diffStatus'], size: 'max-content'},
        {columns: ['state'], size: 'minmax(120px, max-content)'},
        {columns: ['receivers'], size: 'minmax(120px, auto)'},
        {columns: ['ipVersion'], size: 'minmax(120px, auto)'},
        {columns: ['statements'], size: 'minmax(120px, auto)'},
        {columns: ['note'], size: 'minmax(60px, max-content)'},
        {columns: ['buttons'], size: 'min-content'},
      ],
      {
        maxWidth: 1366,
        template(columns) {
          if (GridUtils.hasOptionalColumns(columns)) {
            //all column breakpoint
            return [
              {columns: ['checkboxes'], size: 'max-content'},
              {columns: ['rowStatus'], size: 'max-content'},
              {columns: ['diffStatus'], size: 'max-content'},
              {columns: ['state'], size: 'minmax(120px, max-content)'},
              {columns: ['receivers'], size: 'minmax(120px, auto)'},
              {columns: ['ipVersion'], size: 'minmax(120px, auto)'},
              {columns: ['statements'], size: 'minmax(120px, auto)'},
              {columns: ['note'], size: 'minmax(60px, max-content)'},
              {columns: ['buttons'], size: 'min-content'},
            ];
          }

          return [
            {columns: ['checkboxes'], size: 'max-content'},
            {columns: ['rowStatus'], size: 'max-content'},
            {columns: ['diffStatus'], size: 'max-content'},
            {columns: ['state'], size: 'minmax(120px, max-content)'},
            {columns: ['receivers'], size: 'minmax(120px, auto)'},
            {columns: ['ipVersion'], size: 'minmax(120px, auto)'},
            {columns: ['statements'], size: 'minmax(120px, auto)'},
            {columns: ['note'], size: 'minmax(60px, max-content)'},
            {columns: ['buttons'], size: 'min-content'},
          ];
        },
      },
      {
        maxWidth: 1152,
        template(columns) {
          if (GridUtils.hasOptionalColumns(columns)) {
            //all column breakpoint
            return [
              {columns: ['checkboxes'], size: 'max-content'},
              {columns: ['rowStatus'], size: 'max-content'},
              {columns: ['diffStatus'], size: 'max-content'},
              {columns: ['state'], size: 'minmax(120px, max-content)'},
              {columns: ['receivers'], size: 'minmax(120px, auto)'},
              {columns: ['ipVersion', 'statements'], size: 'minmax(120px, auto)'},
              {columns: ['note'], size: 'minmax(60px, max-content)'},
              {columns: ['buttons'], size: 'min-content'},
            ];
          }

          return [
            {columns: ['checkboxes'], size: 'max-content'},
            {columns: ['rowStatus'], size: 'max-content'},
            {columns: ['diffStatus'], size: 'max-content'},
            {columns: ['state'], size: 'minmax(120px, max-content)'},
            {columns: ['receivers'], size: 'minmax(120px, auto)'},
            {columns: ['ipVersion', 'statements'], size: 'minmax(120px, auto)'},
            {columns: ['note'], size: 'minmax(60px, max-content)'},
            {columns: ['buttons'], size: 'min-content'},
          ];
        },
      },
      {
        maxWidth: 960,
        template(columns) {
          if (GridUtils.hasOptionalColumns(columns)) {
            //all column breakpoint
            return [
              {columns: ['checkboxes'], size: 'max-content'},
              {columns: ['rowStatus'], size: 'max-content'},
              {columns: ['diffStatus'], size: 'max-content'},
              {columns: ['state'], size: 'minmax(120px, max-content)'},
              {columns: ['receivers', 'ipVersion', 'statements'], size: 'minmax(120px, auto)'},
              {columns: ['note'], size: 'minmax(60px, max-content)'},
              {columns: ['buttons'], size: 'min-content'},
            ];
          }

          return [
            {columns: ['checkboxes'], size: 'max-content'},
            {columns: ['rowStatus'], size: 'max-content'},
            {columns: ['diffStatus'], size: 'max-content'},
            {columns: ['state'], size: 'minmax(120px, max-content)'},
            {columns: ['receivers', 'ipVersion', 'statements'], size: 'minmax(120px, auto)'},
            {columns: ['note'], size: 'minmax(60px, max-content)'},
            {columns: ['buttons'], size: 'min-content'},
          ];
        },
      },
      {
        maxWidth: 800,
        template(columns) {
          if (GridUtils.hasOptionalColumns(columns)) {
            //all column breakpoint
            return [
              {columns: ['checkboxes'], size: 'max-content'},
              {columns: ['rowStatus'], size: 'max-content'},
              {columns: ['diffStatus'], size: 'max-content'},
              {columns: ['state', 'receivers', 'ipVersion', 'statements'], size: 'minmax(120px, auto)'},
              {columns: ['note'], size: 'minmax(60px, max-content)'},
              {columns: ['buttons'], size: 'min-content'},
            ];
          }

          return [
            {columns: ['checkboxes'], size: 'max-content'},
            {columns: ['rowStatus'], size: 'max-content'},
            {columns: ['diffStatus'], size: 'max-content'},
            {columns: ['state', 'receivers', 'ipVersion', 'statements'], size: 'minmax(120px, auto)'},
            {columns: ['note'], size: 'minmax(60px, auto)'},
            {columns: ['buttons'], size: 'min-content'},
          ];
        },
      },
    ],
  }),
);
