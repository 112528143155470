/**
 * Copyright 2024 Illumio, Inc. All Rights Reserved.
 */

import keyMirror from 'keymirror';
import {
  attributeIds,
  attributeLabels,
  logicOperatorIds,
  logicOperatorLabels,
  operatorIds,
  operatorLabels,
  valueIds,
  valueLabels,
} from '../../../LabelRulesConstants';

/**
 * kv pair of Selector resource IDs.
 * @type {{[key: string]: string}}
 */
export const resourceIds = keyMirror({
  attribute: null,
  operator: null,
  attributeAndOperator: null,
  expression: null,
});

export const operatorOptions = {
  [operatorIds.is]: {id: operatorIds.is, label: operatorLabels.is},
  [operatorIds.startsWith]: {id: operatorIds.startsWith, label: operatorLabels.startsWith},
  [operatorIds.contains]: {id: operatorIds.contains, label: operatorLabels.contains},
  [operatorIds.endsWith]: {id: operatorIds.endsWith, label: operatorLabels.endsWith},
  [operatorIds.isIn]: {id: operatorIds.isIn, label: operatorLabels.isIn},
  [operatorIds.regex]: {id: operatorIds.regex, label: operatorLabels.regex},
};

export const logicOperatorOptions = {
  [logicOperatorIds.and]: {id: logicOperatorIds.and, label: logicOperatorLabels.and},
  [logicOperatorIds.not]: {id: logicOperatorIds.not, label: logicOperatorLabels.not},
  [logicOperatorIds.or]: {id: logicOperatorIds.or, label: logicOperatorLabels.or},
};

export const valueOptions = {
  [attributeIds.os]: {
    [valueIds.os.windows]: {id: valueIds.os.windows, label: valueLabels.os.windows},
    [valueIds.os.linux]: {id: valueIds.os.linux, label: valueLabels.os.linux},
    [valueIds.os.darwin]: {id: valueIds.os.darwin, label: valueLabels.os.darwin},
    [valueIds.os.aix]: {id: valueIds.os.aix, label: valueLabels.os.aix},
    [valueIds.os.solaris]: {id: valueIds.os.solaris, label: valueLabels.os.solaris},
  },
};

export const attributeOptions = {
  [attributeIds.hostname]: {
    id: attributeIds.hostname,
    label: attributeLabels.hostname,
    operators: [
      operatorOptions.is,
      operatorOptions.startsWith,
      operatorOptions.contains,
      operatorOptions.endsWith,
      // TODO: disable regex before pushing to POC2
      // operatorOptions.regex,
    ],
  },
  [attributeIds.os]: {
    id: attributeIds.os,
    label: attributeLabels.os,
    operators: [operatorOptions.is, operatorOptions.startsWith, operatorOptions.contains, operatorOptions.endsWith],
    values: [
      valueOptions.os.windows,
      valueOptions.os.linux,
      valueOptions.os.darwin,
      valueOptions.os.aix,
      valueOptions.os.solaris,
    ],
  },
  [attributeIds.ip]: {id: attributeIds.ip, label: attributeLabels.ip, operators: [operatorOptions.isIn]},
  [attributeIds.port]: {
    id: attributeIds.port,
    label: attributeLabels.port,
    operators: [operatorOptions.is, operatorOptions.isIn],
  },
  [attributeIds.process]: {
    id: attributeIds.process,
    label: attributeLabels.process,
    operators: [
      operatorOptions.is,
      operatorOptions.startsWith,
      operatorOptions.contains,
      operatorOptions.endsWith,
      // TODO: disable regex before pushing to POC2
      // operatorOptions.regex,
    ],
  },
};

/* the default attribute option when none is selected */
export const defaultAttributeOption = attributeOptions.hostname;

// the individual attribute options can be toggled on/off in the array below; each attribute's operator options can be
// toggled on/off in the attribute's 'operators' array (e.g. attributeOptions.hostname.operators);
export const enabledAttributeOptions = [
  attributeOptions.hostname,
  attributeOptions.os,
  attributeOptions.ip,
  attributeOptions.port,
  attributeOptions.process,
];
